@tailwind base;

::selection {
  background: black;
  color: #fff;
}

@tailwind components;

@layer base {
  @font-face {
    font-family: "Klarheit";
    src: url(/public/fonts/Klarheit/KlarheitKurrent-Semibold.woff2)
      format("woff2");
  }
  @font-face {
    font-family: "Apercu";
    src: url(/public/fonts/Apercu/apercu-light.woff2) format("woff2");
  }
  @font-face {
    font-family: "ApercuMedium";
    src: url(/public/fonts/Apercu/apercu-medium.woff2) format("woff2");
  }
  h1,
  h2 {
    @apply font-heading;
  }
  h3,
  h4,
  h5,
  h6 {
    @apply font-sans;
  }
}

.lucide {
  @apply stroke-[1.2];
}

th,
td {
  box-sizing: border-box;
  /* transition: width 0.2s ease; */
}

.table-container {
  overflow-x: auto;
  will-change: transform;
}

table {
  backface-visibility: hidden;
}
/* Disable text selection and hover effects during resize */
th {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Disable hover effects when resizing */
body:has(.resizing) .amenda-table-heading:hover {
  background: inherit !important;
}

/* Disable hover states during resize */
.resizing .group\/th:hover {
  background: initial;
}

/* Safari specific fixes */
@supports (-webkit-hyphens: none) {
  .amenda-table-heading {
    -webkit-backface-visibility: hidden;
    transform: translateZ(0);
  }
}
.lucide * {
  vector-effect: non-scaling-stroke;
}

.amenda-w-inherit {
  width: inherit !important;
}

.fr-carouselTitle,
.fr-carouselSubtitle,
.fr-carouselCardTitle,
.fr-carouselCardSubtitle,
.fr-smallTitle,
.fr-smallSubtitle,
.fr-bannerMediumTitle,
.fr-bannerMediumSubtitle,
.fr-checklistTitle,
.fr-checklistSubtitle,
.fr-checklistStepLabel,
.fr-checklistStepTitle,
.fr-checklistStepSubtitle {
  @apply text-gray-900;
}

.fr-bannerButton {
  @apply rounded-none bg-gray-900 text-white hover:bg-gray-700;
}
.fr-bannerContainer {
  @apply rounded-none border border-gray-200;
}
.fr-checklistStepItem {
  @apply border-gray-200;
}
.fr-carouselCard {
  @apply hover:border-gray-900;
}

.amenda-page-title {
  @apply mr-2 text-xl leading-loose text-gray-900 lg:text-2xl;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapbox-improve-map {
  display: none;
}
.mapboxgl-ctrl-bottom-right {
  display: none;
}
.amenda-form-layout {
  @apply border-b border-gray-200 bg-white py-5;
}

.amenda-form-heading {
  @apply text-xl leading-6 text-gray-900;
}

.amenda-settings-heading {
  @apply text-lg text-gray-900;
}

.amenda-settings-subline {
  @apply mt-2 text-sm text-gray-700;
}

.amenda-contact-subline {
  @apply truncate text-left text-sm text-gray-500;
}

.amenda-contact-detail {
  @apply mt-1 truncate text-sm text-gray-900;
}

.amenda-table-heading {
  @apply py-2.5 pl-3 pr-2 text-left text-sm font-normal text-gray-900;
  transition: box-shadow 0.2s ease;
}

.amenda-button {
  @apply flex w-full justify-center border border-transparent bg-gray-900 px-4 py-2 text-sm text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2;
}
.amenda-button-light {
  @apply flex w-full justify-center border border-gray-900 bg-white px-4 py-2 text-sm text-gray-900 hover:bg-gray-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2;
}

.amenda-component-label {
  @apply text-sm text-gray-500;
}

.amenda-component {
  @apply block w-full border-gray-300 focus:border-gray-500 focus:outline-none focus:ring-0 focus:ring-gray-900 sm:text-sm;
}

.amenda-component input[type="checkbox"]:checked + .checkmark {
  @apply border-gray-900 text-gray-900;
}

.amenda-no-border-right {
  border-right-color: transparent !important;
}

.amenda-no-ring-around-input input {
  --tw-ring-color: none !important;
}

.amenda-paper-sizes.amenda-a3 {
  width: 70rem;
  max-width: 70rem;
}
.amenda-paper-sizes.amenda-a4 {
  width: 50rem;
  max-width: 50rem;
}
.amenda-paper-sizes.amenda-a5 {
  width: 35rem;
  max-width: 35rem;
}

.amenda-no-wheel-for-input-number input::-webkit-inner-spin-button,
.amenda-no-wheel-for-input-number input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.amenda-no-wheel-for-input-number input[type="number"] {
  -moz-appearance: textfield;
}

.amenda-tab-list {
  @apply flex space-x-4 border-b border-gray-200;
}

.amenda-tab {
  @apply cursor-pointer whitespace-nowrap border-b-2 px-1 py-3 font-sans text-sm uppercase text-gray-500 focus:outline-none active:border-gray-500;
}

.amenda-phone-number-input-field {
  width: 100% !important;
}

.amenda-date-picker .react-datepicker__tab-loop {
  @apply fixed z-20;
}

.amenda-date-picker .react-datepicker-wrapper {
  @apply w-full;
}

.amenda-date-picker .react-datepicker__triangle {
  @apply !fill-white !stroke-gray-300 !stroke-1;
}

.amenda-date-picker .react-datepicker__header {
  @apply border-b border-gray-300 bg-white;
}

.amenda-date-picker.amenda-date-picker-portal > div:nth-child(2) {
  @apply w-full;
}

/*
TODO: check if you can get lib to add day to renderQuarterContents
*/
.amenda-date-picker .react-datepicker__quarter-text {
  @apply !rounded-sm bg-white px-3 py-2 !font-normal text-gray-800 hover:bg-gray-200 hover:text-gray-800;
}
.amenda-date-picker .react-datepicker__quarter-text--disabled {
  @apply !cursor-not-allowed;
}
.amenda-date-picker .react-datepicker__quarter-text--selected {
  @apply !bg-gray-800 !text-white hover:!bg-gray-500 hover:!text-white;
}

.amenda-input-editable {
  @apply h-5 w-full border-0 py-0 text-sm placeholder:text-xs focus:outline-none focus:ring-0;
}

.amenda-pulse-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.amenda-pulse-loader div {
  position: absolute;
  border-width: 4px;
  opacity: 1;
  border-radius: 50%;
  animation: amenda-pulse-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.amenda-pulse-loader div:nth-child(2) {
  animation-delay: -0.5s;
}

.amenda-lazy-load-wrapper {
  @apply absolute !flex h-full w-full items-center justify-center;
}

.amenda-widget:hover {
  /* Start the shake animation and make the animation last for 1 seconds */
  /* animation: amenda-tilt-shake 1s; currently not well working*/
  /* animation-iteration-count: infinite; */
  cursor: move;
}
.amenda-widget:active {
  cursor: grabbing;
}

.amenda-disable-dragging .react-resizable-handle-se {
  cursor: default !important;
}

.amenda-range-slider::-webkit-slider-thumb {
  appearance: none;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: currentColor;
  cursor: grab;
}

.amenda-range-slider::-moz-range-thumb {
  appearance: none;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: currentColor;
  cursor: grab;
}

.amenda-range-slider.mini::-webkit-slider-thumb {
  width: 1.25rem;
  height: 1.25rem;
}

.amenda-range-slider.mini::-moz-range-thumb {
  width: 1.25rem;
  height: 1.25rem;
}

.amenda-container-custom {
  width: 2480px !important;
}

.amenda-tooltip {
  @apply z-[100] shadow-md;
}

.amenda-menu-button {
  @apply flex w-full items-center text-sm text-gray-600 hover:text-gray-900;
}

.amenda-menu-items {
  @apply absolute right-0 z-50 origin-top-right divide-y divide-gray-100 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.active-row {
  background-color: #d6cdcd;
}

.amenda-star {
  @apply absolute h-4 w-4 before:absolute before:block before:h-full before:rounded-3xl before:content-[''] after:absolute after:block after:h-full after:rounded-3xl after:content-[''];
}
.amenda-star::before,
.amenda-star::after {
  width: 20%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.amenda-star::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.amenda-sparkle {
  @apply absolute h-2.5 w-2.5 rounded-full;
}

.amenda-sparkle.medium,
.amenda-star.medium {
  @apply scale-50;
}

.amenda-sparkle.small,
.amenda-star.small {
  @apply scale-[0.1];
}

.amenda-pulse1 {
  animation: pulse 1s linear infinite;
}
.amenda-pulse2 {
  animation: pulse 1.1s 300ms linear infinite;
}
.amenda-pulse3 {
  animation: pulse 0.8s 600ms linear infinite;
}
.amenda-pulse4 {
  animation: pulse 1.3s 900ms linear infinite;
}

.amenda-dropzone .uppy-Dashboard-inner {
  @apply bg-white;
}

.amenda-modal {
  @apply px-4 md:px-3 lg:px-4;
}

.amenda-roman-list {
  counter-reset: section;
}
.amenda-roman-list h3::before {
  counter-increment: section;
  content: counter(section, upper-roman) ". ";
}

/* Ensure that scrollbar styles are applied to webkit browsers */
::-webkit-scrollbar {
  width: 5px; /* Adjust thickness as needed */
  height: 5px;
  padding-top: 8px; /* Add padding top to the horizontal scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* You can customize the background color here */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a202c; /* Use gray-900 color */
  border-radius: 0px; /* Adjust as needed */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2d3748; /* Darken the color on hover if desired */
}

@keyframes pulse {
  0% {
    transform: scale(0.45);
  }
  90% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(0.45);
  }
}

@keyframes amenda-pulse-loader {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes amenda-tilt-shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@tailwind utilities;
